<template>
	<v-window v-model="step" touchless>
		<v-window-item :value="1">
			<v-container fluid fill-height>
				<v-row align="center" justify="center" no-gutters>
					<v-col cols="12">
						<base-heading :title="title">
							<base-btn v-if="inventory.length>0" dark color="green darken-1" @click="showReportOptions()" icon="mdi-file-chart" btnText="Reportes"></base-btn>
						</base-heading>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<base-data-table 
							v-model="sortBy"
							:headers="headers" 
							:items="inventory" 
							showEnabledField
							newItemButtonText='Movimientos'
							newItemButtonIcon='mdi-table-edit'
							@loadDataTable="toList"
							@newItem="step = 2"
						>
							<template v-slot:item.options="{ item }">
								<base-icon-btn color="secondary" @click="updateProduct(item)" icon="mdi-reload" tooltip="Actualizar Producto"></base-icon-btn>
							</template>

							<template v-slot:item.image="{ item }">
								<div style="width:100px;">
									<base-product-img v-model="item.image" :productId="item.productId"
										class="my-2"
										gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
										aspect-ratio="1.5"
										contain
									>
									</base-product-img>
								</div>
							</template>

							<template v-for="prop in getStockHeaders()" v-slot:[`item.${prop}`]="{ item }">
								<v-chip v-if="validStockDetails(item, prop, item[prop])" :key="prop" :color="getColor(item[prop])" dark @click="showDetails(item, prop)">{{ item[prop] }}</v-chip>
								<v-chip v-else :key="prop" :color="getColor(item[prop])" dark>{{ item[prop] }}</v-chip>
							</template>
						</base-data-table>
					</v-col>
				</v-row>

				<v-dialog v-model="showReportOptionsModal" max-width="500px">
					<base-form-container
						title="Opciones de informe"
					>
						<template v-slot:content>
							<v-scale-transition mode="out-in">
								<v-row v-if="!detailedOptionChosen" key="options" no-gutters>
									<v-col cols="12" sm="12">
										<v-checkbox class="pt-0" color="info"
											v-model="includeImages"
											label="¿Incluir imagenes en el reporte?"
										></v-checkbox>
									</v-col>
									<v-col cols="12" sm="6" align="center" class="py-4">
										<base-btn dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte General"></base-btn>
									</v-col>

									<v-col cols="12" sm="6" align="center" class="py-4">
										<base-btn dark color="secondary" @click="showDetailedReportOptions()" icon="mdi-file-chart" btnText="Por Sucursal"></base-btn>
									</v-col>
								</v-row>

								<v-row v-else key="detailed" no-gutters>
									<v-col cols="12" sm="6">
										<base-select v-model="reportBranchOfficeId" :items="branchOffices" label="Sucursal" class="mr-0 mr-sm-2"
										:rules="[commonValRules.required]" required></base-select>
									</v-col>
									<v-col cols="12" sm="6">
										<v-checkbox class="ml-0 ml-sm-2 pt-0" color="info"
											v-model="includeImages"
											label="¿Incluir imagenes en el reporte?"
										></v-checkbox>
									</v-col>

									<v-col cols="12" sm="12" align="center" class="py-4">
										<base-btn dark color="secondary" @click="getDetailedReportView()" icon="mdi-file-chart" btnText="Generar Reporte"></base-btn>
									</v-col>
								</v-row>
							</v-scale-transition>
						</template>
					</base-form-container>
				</v-dialog>

				<v-dialog v-if="showStockDetailsDialog" v-model="showStockDetailsDialog" max-width="650px">
					<base-form-container
						:title="selectedStockDetails.branchOffice"
					>
						<template v-slot:content>
							<base-stock-details-picker :title="selectedStockDetails.title" :productId="selectedStockDetails.productId" :stockFormId="selectedStockDetails.stockFormId" :stockGroup="selectedStockDetails.details" detailMode="ShowStockMovement">
							</base-stock-details-picker>
						</template>
					</base-form-container>
				</v-dialog>

			</v-container>
		</v-window-item>
		<v-window-item :value="2">
      <admin-inventory-movements v-if="isAdmin" @goBack="closeMovements"></admin-inventory-movements>
			<inventory-movements v-else @goBack="closeMovements"></inventory-movements>
		</v-window-item>
	</v-window>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import { mapGetters } from 'vuex'

export default {
	mixins: [MainExternalData],

	components:{
		AdminInventoryMovements: () => import("./AdminInventoryMovements"),
    InventoryMovements: () => import("./InventoryMovements"),
  },

	data() {
		return {
			title: 'Inventario',
			step: 1,
			sortBy: 'totalStock',
			stockItem: 'stock',
			stockDetailsItem: 'stockDetails',
			inventory: [],
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Imagen", value: "image", sortable: false },
			],
			//StockFlags
			stockFlags: {
				low: 10,
				high: 30
			},
			//SelectedStockDetails
			showStockDetailsDialog: false,
			selectedStockDetails: {
				title: '',
				branchOffice: '',
				productId: 0,
				stockFormId: 0,
				details: []
			},
			//PDF report
			showReportOptionsModal: false,
			detailedOptionChosen: true,
			branchOffices: [],
			reportBranchOfficeId: 0,
			includeImages: false
		}
	},

	computed: {
		 ...mapGetters(['isAdmin']),
	},

	async created() {
		if (await this.getTableHeaders()) {
			this.getStockFlags();
			this.toList();
		}
  },

	methods: {
		getStockHeaders(){
			const result = this.headers.filter(x => x.value.toLowerCase().includes('stock')).map(x => x.value);
			return result;
		},

		validStockDetails(item, stockHeaderValue, stock){
			if (stockHeaderValue.startsWith(this.stockItem)) {
				const branchOfficeId = stockHeaderValue.substr(this.stockItem.length);
				const stockDetails = `${this.stockDetailsItem}${branchOfficeId}`;
				if (stock > 0 && item[`${stockDetails}`] !== undefined) {
					return true;
				}
			}
			return false;
		},

		getColor(stock) {
			if (stock < this.stockFlags.low) return 'red'
			else if (stock > this.stockFlags.high) return 'green'
			else return 'orange'
		},

		async getTableHeaders(){
      let me=this;
			var result = false;
			try {
        me.SHOW_LOADING()
				var headersArray=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId
				};
				let url = 'api/ProductInventory/GetProductInventoryHeaders';
				if (!me.isAdmin) {
					request.branchOfficeId = me.companyData.branchOfficeId;
					url = 'api/ProductInventory/GetProductInventoryHeadersByBranchOffice';
				}
				await me.getObjectResponse(url, request).then(data => {
					if (data !== undefined) {
						headersArray = data.headers;
						headersArray.map(function(x){
							me.headers.push({text:x.text, value:x.value});
						});
						result = true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
			return result;
		},

		async toList(){
			let me=this;
			try {
        me.SHOW_LOADING()
        me.inventory=[]
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId
				};
				let url = 'api/ProductInventory/GetProductInventory';
				if (!me.isAdmin) {
					request.branchOfficeId = me.companyData.branchOfficeId;
					url = 'api/ProductInventory/GetProductInventoryByBranchOffice';
				}
				await me.getObjectResponse(url, request).then(data => {
					if (data === undefined) {
						return;
					}
					me.inventory = data.inventory;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async getStockFlags(){
      let me=this;
      let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId
      };
      await me.getObjectResponse('api/Company/GetStockFlags', request).then(data => {
        if (data === undefined) {
					return;
        }
				me.stockFlags = Object.assign({}, data);
      }).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		async listOfBranchOffices(){
			let me=this;
			try {
        me.SHOW_LOADING()
				me.reportBranchOfficeId = '';
				var branchOfficesArray=[];
				await me.getObjectResponse('api/BranchOffice/Select', me.baseRequest).then(data => {
					if (data !== undefined) {
						branchOfficesArray = data.branchOffices; 
						branchOfficesArray.map(function(x){
							me.branchOffices.push({text:x.visualName, value:x.id});
							if (x.main) {
								me.reportBranchOfficeId = x.id;
							}
						});
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async updateProduct(item){
			let me=this;
			try {
				me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'productId': item.productId
				};
				let url = 'api/ProductInventory/GetInventoryDataByProduct';
				if (!me.isAdmin) {
					request.branchOfficeId = me.companyData.branchOfficeId;
					url = 'api/ProductInventory/GetInventoryDataByProductAndBranchOffice';
				}
				await me.getObjectResponse(url, request).then(data => {
					if (data === undefined) {
						return;
					}
					me.addOrUpdateListItem(data.product);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
      }
		},

		addOrUpdateListItem(inventoryItem){
			let me=this;
			if (!!me.inventory.find((x) => x.productId === inventoryItem.productId)) {
				const index = me.inventory.findIndex((x) => x.productId === inventoryItem.productId)
				me.inventory.splice(index, 1, inventoryItem);
			} else {
				me.inventory.push(inventoryItem);
			}
		},

		closeMovements(){
			this.step = 1;
    },

		async showDetails(item, stockHeaderValue){
			let me = this;
			try {
				me.SHOW_LOADING()

				const branchOfficeId = stockHeaderValue.substr(me.stockItem.length);
				const branchOfficeName = me.headers.find(x => x.value == stockHeaderValue).text;
				const stockDetails = `${me.stockDetailsItem}${branchOfficeId}`;
				var details = JSON.parse(item[`${stockDetails}`]);

				await me.parseStockDetails(details, item.stockFormId).then(data => {
					me.selectedStockDetails.title = `${item.visualName} (${item[stockHeaderValue]})`;
					me.selectedStockDetails.branchOffice = branchOfficeName
					me.selectedStockDetails.productId = item.productId;
					me.selectedStockDetails.stockFormId = item.stockFormId;
					me.selectedStockDetails.details = data;
					me.showStockDetailsDialog = true;
				}).catch(error => {
					console.log(error)
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		parseStockDetails(details, stockFormId) {
			return new Promise(resolve => {
				var result = []
				let cloneDetails = details.slice();

				cloneDetails.map((x) => {
					let stockDetails = []
					if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
						stockDetails.push({
							id:1,
							stock:x.stock
						});
					} else {
						stockDetails = JSON.parse(x.stockDetails);
					}

					result.push({ 
						purchaseId:x.purchaseId,
						stock:x.stock,
						stockDetails:stockDetails
					});
				});

				resolve(result);
			});
		},

		showReportOptions() {
			this.detailedOptionChosen=false; 
			this.showReportOptionsModal=true;
			this.includeImages = false;
		},

		async showDetailedReportOptions() {
			let me=this;
			if (me.isAdmin) {
				await me.listOfBranchOffices();
				me.detailedOptionChosen = true;
				me.includeImages = false;
			}
			else {
				await me.getDetailedReportView();
			}
		},

		async mainReportView() {
			let me=this;
			me.showReportOptionsModal = false;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'includeImages': me.includeImages
				};
				let url = 'api/ProductInventory/GetMainReportView';
				if (!me.isAdmin) {
					request.branchOfficeId = me.companyData.branchOfficeId;
					url = 'api/ProductInventory/GetMainReportViewByBranchOffice';
				}
				await me.getObjectResponse(url, request).then(data => {
					if (data === undefined) {
						return;
					}
					me.$Tools.downloadDocument(data.fileName, data.fileContent);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async getDetailedReportView() {
			let me=this;
			me.showReportOptionsModal = false;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.isAdmin == true ? me.reportBranchOfficeId : me.companyData.branchOfficeId,
					'includeImages': me.includeImages
				};
				await me.getObjectResponse('api/ProductInventory/GetDetailedReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.$Tools.downloadDocument(data.fileName, data.fileContent);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
	},
}
</script>